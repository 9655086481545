import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../constants/routes';

const GiftBlock = ({data, className = '', isMoreCoin = false, ...props}) => {
  const navigate = useNavigate();

  const handleClickGift = useCallback((event) => {
    event.preventDefault();

    navigate(ROUTES.GIFT, {state: {giftId: data?.id ? data?.id : null}});
  }, []);

  return (
    <div
      onClick={handleClickGift}
      className={`overflow-hidden w-full h-full rounded-lg max-w-[200px] shadow-extraSmall ${className}`}
      {...props}
    >
      <div className="relative aspect-square">
        <img className="aspect-square" src={data?.images?.[0]} alt={data?.name || ''} />

        {isMoreCoin && (
          <div className="absolute bottom-0 px-2 py-1 w-full font-semibold text-center text-white bg-overlay06 leading-medium backdrop-blur-small">
            Cần thêm xu
          </div>
        )}
      </div>
      <div className="px-1 py-2">
        <div className="text-base font-normal tracking-tighter truncate leading-medium">
          {data?.name || ''}
        </div>
        {data?.price && (
          <div>
            <span className="text-sm font-normal tracking-tighter text-gray leading-medium">
              Giá trị:
            </span>
            <span className="ml-2 font-semibold text-primary-light leading-medium">
              {data?.price?.toLocaleString() || ''}
            </span>
            <span className="font-semibold underline text-primary-light leading-medium">đ</span>
          </div>
        )}

        {data?.point !== null && (
          <div className="mt-2">
            <span className="text-sm font-normal tracking-tighter text-gray leading-medium">
              Số xu:
            </span>
            <span className="ml-2 font-semibold text-primary-light leading-medium">
              {data?.point || data?.point === 0 ? data?.point : ''}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default GiftBlock;
